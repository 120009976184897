import React from "react";
import "./Reviews.scss";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ReviewBelowContent from "../ReviewBelowContent/ReviewBelowContent";

const Reviews = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiPost(sort: { order: DESC, fields: date },limit:3,
      filter: {blog_categories: {elemMatch: {category: {eq: "Reviews"}}}}) {
        nodes {
          slug
          date
          Title
          id
          description {
            data {
              description
            }
          }
          blog_categories {
            category
            slug
          }
          admin_users {
            username
          }
          ReadTime
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, height: 500)
              }
            }
          }
        }
      }
    }
  `);

  let index = -1;

  return (
    <div className="review-background">
      <div className="reviews-section px-2">
        <div className="container">
          <div className="row py-md-5">
            <span className="reviews-title pb-lg-4 py-5 py-lg-0">REVIEWS</span>
            <div className="mx-auto">
              <div>
                <div className="reviews-content row">
                  {data.allStrapiPost.nodes.map((e) => {
                    index++;
                    return (
                      <div className="col-lg-4" key={e.id}>
                        <Link to={`/${e.slug}/`} className=" d-block">
                          <div className="position-relative">
                            <GatsbyImage
                              image={
                                e?.image?.localFile?.childImageSharp
                                  ?.gatsbyImageData
                              }
                              loading="eager"
                              alt=''
                            // alt="reviews-content"
                            />
                            <div className="read-more">
                              <div className="text">READ MORE</div>
                            </div>
                          </div>
                        </Link>
                        <>
                          <ReviewBelowContent
                            reviewCount={index + 1}
                            categorylink={"/category/reviews"}
                            description={e.Title}
                            descriptionLink={e.slug}
                            categorytitle={"REVIEWS"}
                            adminlink={e.admin_users[0].username.toLowerCase()
                              .replace(" ", "-")}
                            admin={e.admin_users[0].username}
                            date={new Date(e.date)
                              .toDateString()
                              .split(" ")
                              .slice(1, 4)
                              .toString()
                              .replace(",", " ")}
                          />
                        </>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
