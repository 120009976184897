import React from "react";
import "./ArticleCard.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
interface ArticleCards {
  content: "BANNER" | "GADGETS";
  variant: "LARGE" | "SMALL";
  src;
  category;
  discription;
  admin;
  adminSrc;
  adminLink;
  pageLink;
  date: string;
}
const ArticleCard: React.FC<ArticleCards> =
  ({
    variant,
    content,
    src,
    category,
    discription,
    admin,
    adminSrc,
    adminLink,
    pageLink,
    date,
  }) => {
    const styl = () => {
      if (content === "BANNER") {
        return { height: "100%", width: "100%", minHeight: "300px" };
      }
      if (content === "GADGETS") {
        return { height: "100%", width: "100%", minHeight: "400px" };
      }
    };

    return (
      <div className="col-space">

        <div className="banner-img px-2 pe-lg-3 mb-lg-5 w-100">
          <Link to={`/${pageLink}/`}>
            <GatsbyImage
              image={src}
              objectFit="cover"
              style={styl()}
              alt="banner-image"
              className="banner-image"
              loading="eager"
            />
          </Link>
          <div
            className={
              variant === "LARGE" ? "overlay" : "card-component-overlay"
            }
          >
            <ul
              className={
                variant === "LARGE"
                  ? "banner-ul d-flex ps-3 ps-md-3 pb-2"
                  : "banner-ul d-flex ps-3 ps-md-1 pb-2"
              }
            >
              {category &&
                category.map((e) => {
                  return (
                    <li key={e.slug}>
                      <Link to={`/category/${e.category.toLowerCase()}/`}>
                        {e.category}
                      </Link>
                    </li>
                  );
                })}
            </ul>
            <h1
              className={
                variant === "LARGE"
                  ? "spacing px-3 px-md-3 pb-2"
                  : "spacing px-3 px-md-1 pb-2"
              }
            >
              <Link
                to={`/${pageLink}/`}
                dangerouslySetInnerHTML={{ __html: discription }}
              />
            </h1>

            <div className="d-flex admin-img pt-1 align-items-baseline">

              <div
                className={
                  variant === "LARGE" ? "ps-3 ps-md-3" : "ps-3 ps-md-1"
                }
              >
                {/* <div>
                  <GatsbyImage
                    image={
                      adminSrc
                    }
                    className="small-img"
                    loading="eager"
                    alt="admin-img"
                  />
                </div> */}
              </div>
              {/* <Link to={`/${adminLink?.toLowerCase().replace(" ", "-")}/`}> */}
              <div
                className={
                  variant === "LARGE"
                    ? "d-flex align-items-baseline pb-4"
                    : "d-flex align-items-baseline"
                }
              >
                {/* <div className="admin-content">
                    <small>
                      by
                      <span className="ps-2" >
                        {admin}
                      </span>
                    </small>
                  </div> */}
                <div className="august ps-2">
                  <small>{date}</small>
                </div>
              </div>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
export default React.memo(ArticleCard);