import React from "react";
import { Link } from "gatsby";

const ReviewBelowContent = ({
  reviewCount,
  categorylink,
  description,
  descriptionLink,
  categorytitle,
  adminlink,
  admin,
  date,
}) => {
  return (
    <>
      <div className="row pt-4 mb-md-4 pb-md-1">
        <div className="col-lg-2 col-md-1 col-2 px-0 review-col-index">
          <div className="number text-center reviews-right-border">
            <h2>{reviewCount}</h2>
          </div>
        </div>
        <div className="col-10 ps-0">
          <div className="content">
            <h2 className="mb-0">
              <Link
                to={`/${descriptionLink}/`}
                dangerouslySetInnerHTML={{ __html: description }}
              ></Link>
            </h2>
          </div>
          <div className="post-categories mt-5">
            <ul className="d-flex pb-5 pb-md-0 ">
              <Link to={`${categorylink}/`}>
                <li className="reviews">{categorytitle}</li>
              </Link>
              {/* <Link to={`/${adminlink}/`}>
                <li className="admin ps-3">
                  <div className="ps-1">
                    <span className="px-lg-2 ps-2 ps-lg-0">by {admin}</span>
                  </div>
                </li>
              </Link> */}
              <li className="august">{date}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ReviewBelowContent);
