import { Link } from "gatsby";
import React from "react";

import "./NewsBelowContent.scss";

const NewsBelowContent = ({
  category,
  description,
  descriptionLink,
  adminlink,
  admin,
  date,
}) => {
  return (
    <>
      <div className="news-content py-3">
        <ul className="d-flex news-phones phones-list pt-3">
          {category &&
            category.map((e) => {
              return (
                <li key={e.slug} className="text-uppercase pb-2 pe-2">
                  <Link
                    className="link-category"
                    to={`/category/${e.category?.toLowerCase()}/`}
                  >
                    {e.category}
                  </Link>
                </li>
              );
            })}
        </ul>
        <h2 className="py-2">
          <Link
            className="phones-heading"
            to={`/${descriptionLink}/`}
            dangerouslySetInnerHTML={{ __html: description }}
          ></Link>
        </h2>
        <ul className="d-flex pt-2 pb-0 pb-md-4 desc-list align-items-baseline">
          {/* <Link to={`/${adminlink.replace(" ", "-")}/`} className="ps-1">
            <small className="admin admin-dot fw-normal">
              by
              <span className="px-lg-2 fw-bold">{admin}</span>
            </small>
          </Link> */}
          <li className="august ps-2">{date}</li>
        </ul>
      </div>
    </>
  );
};

export default React.memo(NewsBelowContent);