import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const BannerRightContent = (() => {

  const data = useStaticQuery(graphql`
    {
      allStrapiPost(sort: {order: DESC, fields: date}, limit: 5, skip: 3) {
        nodes {
          slug
          date
          Title
          id
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, height: 500)
              }
            }
          }
        }
      }
    }
  `);

  const currentDate = () => {
    const today = new Date();
    const fullMonth = today.toLocaleString("default", { month: "long" });
    const fullDay = today.getDate();
    const fullYear = today.getFullYear();
    const currentDay = fullMonth + " " + fullDay + ",  " + fullYear;
    return currentDay;
  };

  return (
    <div className="px-2 px-md-0 pb-5 pb-md-0">
      <div className="banner_right pb-3 pt-4 pt-md-0">
        <div className="row align-items-center">
          <div className="col-3">
            <span className="heading-today">TODAY</span>
          </div>
          <div className="col-9">
            <p className="mb-0 text-end today-date">{currentDate()}</p>
          </div>
        </div>
      </div>
      <div className="banner-right-content py-3">
        {data.allStrapiPost.nodes.map((e, i) => {
          return (
            <div className="banner-content-list position-relative" key={e.id} >
              <Link to={`/${e.slug}/`}>
                <GatsbyImage
                  image={e.image.localFile?.childImageSharp?.gatsbyImageData}
                  style={{ height: "100%", width: "100%" }}
                  alt="reviews-content"
                  className="img-fluid position-absolute"
                  loading="eager"
                  objectFit="cover"
                />
                <div className="bg-element"></div>
                <div className="row align-items-start position-relative banner-text">
                  <div className="col-2 pt-2 ">
                    <div className="number number-right-banner text-center banner-right-border mb-md-4">
                      <h2>{i + 1}</h2>
                    </div>
                  </div>
                  <div className="col-10 ps-0 col-right-content">
                    <div className="content content-right-banner">
                      <h2><span dangerouslySetInnerHTML={{ __html: e.Title.slice(0, 52) }}></span></h2>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default React.memo(BannerRightContent);