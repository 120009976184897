import React from "react";
import "./Gadgets.scss";
import ArticleCard from "../ArticleCard/ArticleCard";
import { graphql, useStaticQuery } from "gatsby";

const Gadgets = () => {
  const data = useStaticQuery(graphql`
    {
  allStrapiPost(
    sort: { order: DESC, fields: date }
    filter: {blog_categories: {elemMatch: {category: {eq: "Gadgets"}}}}
    limit: 3
  ) {
    nodes {
      slug
      date
      Title
      id
      blog_categories {
        category
        slug
      }
      admin_users {
        username
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      adminImg {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 40)
          }
        }
      }
    }
  }
}
  `);

  const gadgets1 = data.allStrapiPost.nodes[0];
  const gadgets2 = data.allStrapiPost.nodes[1];
  const gadgets3 = data.allStrapiPost.nodes[2];

  return (
    <div className="gadgets-section">
      <div className="container">
        <div className="row pt-5 pt-md-0">
          <div className="gadgets-title pb-2 ms-2">
            <strong>Gadgets</strong>
          </div>
          <React.Fragment key={""}>
            <div className="col-lg-6 py-4">
              <div className="gadgets-left-img  w-100">
                <ArticleCard
                  variant="LARGE"
                  content="GADGETS"
                  src={gadgets1.image.localFile.childImageSharp.gatsbyImageData}
                  category={gadgets1.blog_categories}
                  discription={gadgets1.Title}
                  admin={gadgets1.admin_users[0].username}
                  adminSrc={
                    gadgets1.adminImg.localFile.childImageSharp.gatsbyImageData
                  }
                  adminLink={gadgets1.admin_users[0].username.toLowerCase().replace(" ", "-")}
                  pageLink={gadgets1.slug}
                  date={new Date(gadgets1.date)
                    .toDateString()
                    .split(" ")
                    .slice(1, 4)
                    .toString()
                    .replace(",", " ")}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row h-100">
                <div className="col-sm-12 col-md-6">
                  <div className="gadgets-right-image w-100 py-4">
                    <ArticleCard
                      variant="SMALL"
                      content="GADGETS"
                      src={
                        gadgets2.image.localFile.childImageSharp.gatsbyImageData
                      }
                      category={gadgets2.blog_categories}
                      discription={gadgets2.Title}
                      admin={gadgets2.admin_users[0].username}
                      adminSrc={
                        gadgets2.adminImg.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      adminLink={gadgets2.admin_users[0].username.toLowerCase().replace(
                        " ",
                        "-"
                      )}
                      pageLink={gadgets2.slug}
                      date={new Date(gadgets2.date)
                        .toDateString()
                        .split(" ")
                        .slice(1, 4)
                        .toString()
                        .replace(",", " ")}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="gadgets-right-image w-100 py-4">
                    <ArticleCard
                      variant="SMALL"
                      content="GADGETS"
                      src={
                        gadgets3?.image.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      category={gadgets3.blog_categories}
                      discription={gadgets3.Title}
                      admin={gadgets3.admin_users[0].username}
                      adminSrc={
                        gadgets3.adminImg.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      pageLink={gadgets3.slug}
                      adminLink={gadgets3.admin_users[0].username.toLowerCase().replace(
                        " ",
                        "-"
                      )}
                      date={new Date(gadgets3.date)
                        .toDateString()
                        .split(" ")
                        .slice(1, 4)
                        .toString()
                        .replace(",", " ")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Gadgets);
