import React, { useEffect, useState } from "react";
import "./BannerCard.scss";
import BannerRightContent from "../BannerRightContent/BannerRightContent";
import ArticleCard from "../ArticleCard/ArticleCard";
import { graphql, useStaticQuery } from "gatsby";
import SubscribePopup from "../SubscribePopup/SubscribePopup";

const BannerCard = (() => {
  const [pop, setPop] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setPop(true)
    }, 10000);
  }, [])

  const data = useStaticQuery(graphql`
    {
      allStrapiPost(sort: { fields: date, order: DESC }, limit: 3) {
        nodes {
          slug
          date
          Title
          description {
            data {
              description
            }
          }
          id
          blog_categories {
            category
            slug
          }
          admin_users {
            username
          }
          ReadTime
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 540,quality: 50, sizes:"50" )
              }
            }
          }
          adminImg {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 40)
              }
            }
          }
        }
      }
    }
  `);
  const banner1 = data.allStrapiPost.nodes[0];
  const banner2 = data.allStrapiPost.nodes[1];
  const banner3 = data.allStrapiPost.nodes[2];

  return (
    <>
      {pop ? <>
        <SubscribePopup onclick={setPop} />
      </> : null}

      <div className="Phones-section pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-8 Phones-height-large">
              <ArticleCard
                variant="LARGE"
                content="BANNER"
                src={banner1.image.localFile.childImageSharp.gatsbyImageData}
                category={banner1.blog_categories}
                discription={banner1.Title}
                admin={banner1.admin_users[0].username}
                adminSrc={
                  banner1.adminImg.localFile.childImageSharp.gatsbyImageData
                }
                adminLink={banner1.admin_users[0].username}
                pageLink={banner1.slug}
                date={new Date(banner1.date)
                  .toDateString()
                  .split(" ")
                  .slice(1, 4)
                  .toString()
                  .replace(",", " ")}
              />
              <div className="row banner-row py-3 py-4 py-md-4 py-xl-0">
                <div className="col-md-6 Phones-height-small py-3 py-lg-0">
                  <ArticleCard
                    variant="SMALL"
                    content="BANNER"
                    src={banner2.image.localFile.childImageSharp.gatsbyImageData}
                    category={banner2.blog_categories}
                    discription={banner2.Title}
                    admin={banner2.admin_users[0].username}
                    adminSrc={
                      banner2.adminImg.localFile.childImageSharp.gatsbyImageData
                    }
                    adminLink={banner2.admin_users[0].username.toLowerCase().replace(" ", "-")}
                    pageLink={banner2.slug}
                    date={new Date(banner2.date)
                      .toDateString()
                      .split(" ")
                      .slice(1, 4)
                      .toString()
                      .replace(",", " ")}
                  />
                </div>
                <div className="col-md-6 Phones-height-small py-3 py-lg-0">
                  <ArticleCard
                    variant="SMALL"
                    content="BANNER"
                    src={banner3.image.localFile.childImageSharp.gatsbyImageData}
                    category={banner3.blog_categories}
                    discription={banner3.Title}
                    admin={banner3.admin_users[0].username}
                    adminSrc={
                      banner3.adminImg.localFile.childImageSharp.gatsbyImageData
                    }
                    adminLink={banner3.admin_users[0].username?.toLowerCase().replace(" ", "-")}
                    date={new Date(banner3.date)
                      .toDateString()
                      .split(" ")
                      .slice(1, 4)
                      .toString()
                      .replace(",", " ")}
                    pageLink={banner3.slug}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <BannerRightContent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default React.memo(BannerCard)
