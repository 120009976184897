import React from "react";
import "./News.scss";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import NewsBelowContent from "../NewsBelowContent/NewsBelowContent";

const News = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiPost(sort: { order: DESC, fields: date },
        filter: {blog_categories: {elemMatch: {category: {eq: "News"}}}}
        limit:6) {
        nodes {
          slug
          date
          Title
          id
          description {
            data {
              description
            }
          }
          blog_categories {
            category
            slug
          }
          admin_users {
            username
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="news-section pt-4 pt-lg-0 ">
      <div className="container">
        <div className="row pt-md-5 ">
          <span className="news-title py-4 ps-3 ps-md-3">NEWS</span>
          <div className="mx-auto">
            <div>
              <div className={"reviews-content row"}>
                {data.allStrapiPost.nodes.map((e, i) => {
                  return (
                    <div className="col-lg-4 col-md-6 px-3" key={e.id}>
                      <Link to={`/${e.slug}/`}>
                        <div className="position-relative py-1">
                          <GatsbyImage
                            image={
                              e.image.localFile?.childImageSharp
                                ?.gatsbyImageData
                            }
                            style={{
                              height: "100%",
                              width: "100%",
                              minHeight: "270px",
                            }}
                            imgStyle={{ height: "100%" }}
                            alt="news-content"
                            className="img-fluid"
                            loading="eager"
                            objectFit="cover"
                          />
                          <div className="read-more read-more-news">
                            <div className="text">READ MORE</div>
                          </div>
                        </div>
                      </Link>
                      <>
                        <NewsBelowContent
                          description={e.Title}
                          descriptionLink={e.slug}
                          category={e.blog_categories}
                          adminlink={e.admin_users[0].username.toLowerCase().replace(
                            " ",
                            "-"
                          )}
                          admin={e.admin_users[0].username}
                          date={new Date(e.date)
                            .toDateString()
                            .split(" ")
                            .slice(1, 4)
                            .toString()
                            .replace(",", " ")}
                        />
                      </>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(News);