import React from "react";
import "./Specifications.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";

const Specifications = () => {
  const data = useStaticQuery(graphql`
  {
  allStrapiPost(
    sort: {order: DESC, fields: date}
    limit: 5
    filter: {blog_categories: {elemMatch: {category: {eq: "Spec"}}}}
  ) {
    nodes {
      slug
      date
      Title
      id
      description {
        data {
          description
        }
      }
      blog_categories {
        category
        slug
      }
      admin_users {
        username
      }
      ReadTime
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
}
`);

  let index = 1;

  return (
    <div className="specifications-section pt-4 pt-lg-0">
      <div className="container">
        <div className="specifications-title pb-4 ms-2 ps-1">
          <span>SPECIFICATIONS</span>
        </div>
        <div className="row">
          <div className="col-lg-6 specifications-first px-4">
            {data.allStrapiPost.nodes.slice(0, 1).map((e) => {

              return (
                <div key={e.id}>
                  <Link to={`/${e.slug}/`}>
                    <div className="specification_image">
                      <GatsbyImage
                        image={
                          e.image.localFile?.childImageSharp?.gatsbyImageData
                        }
                        style={{
                          height: "100%",
                          width: "100%",
                          minHeight: "300px",
                        }}
                        imgStyle={{ height: "100%" }}
                        loading="eager"
                        alt="specs-content"
                        className="img-fluid"
                        objectFit="cover"
                      />
                      <div className="read-more">
                        <div className="text">READ MORE</div>
                      </div>
                    </div>
                  </Link>

                  <div className="specification-left-content d-flex text-left">
                    <div className="number-one text-center">
                      <h2 className="pe-2">{index}</h2>
                    </div>
                    <div className="content">
                      <h2 className="mb-0">
                        <Link to={`/${e.slug}/`} className="link-tag">
                          <div
                            className="new-content py-3"
                            dangerouslySetInnerHTML={{ __html: e.Title }}
                          ></div>
                        </Link>
                      </h2>
                      <p className="specification-para mb-0">{e.description.data.description.substring(0, 191)}...</p>
                      <div className="spec-post-categories py-3">
                        <ul className="d-flex pb-5 pt-4">
                          {e.blog_categories.map((a, i) => {
                            return (
                              <li
                                className="reviews text-uppercase"
                                key={a.slug}
                              >
                                <Link
                                  to={`/category/${a.category.toLowerCase()}/`}
                                >
                                  {a.category}
                                  {e.category?.length - 1 !== i ? "," : ""}
                                </Link>
                              </li>
                            );
                          })}
                          {/* <Link to={`/${e.admin_users[0].username?.replace(
                            " ",
                            "-"
                          )}/`}>
                            <li className="admin ps-lg-2">
                              by
                              <span className="ps-2 pe-2"> {e.admin_users[0].username}</span>
                            </li>
                          </Link> */}
                          <li className="august ps-2">
                            {new Date(e.date)
                              .toDateString()
                              .split(" ")
                              .slice(1, 4)
                              .toString()
                              .replace(",", " ")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="col-lg-6 px-4">
            <div className="row">
              {data.allStrapiPost.nodes.slice(1, 5).map((e, i) => {
                return (
                  <React.Fragment key={e.id}>
                    <div className="col-md-6 col-lg-6 ">
                      <Link to={`/${e.slug}/`}>
                        <div className="specification_image">
                          <GatsbyImage
                            className="gatsbyImage"
                            image={
                              e.image.localFile?.childImageSharp
                                ?.gatsbyImageData
                            }
                            loading="eager"
                            alt="specs-content"
                          />
                          <div className="read-more">
                            <div className="text">READ MORE</div>
                          </div>
                        </div>
                      </Link>

                      <div className="specification-right-content d-flex pt-4 pb-1 pb-md-0 mb-5 justify-content-start align-items-start">
                        <div className="number text-center reviews-right-border">
                          <h2>{i + 2}</h2>
                        </div>
                        <div className="content">
                          <h2 className="mb-0">
                            <Link to={`/${e.slug}/`} className="link-tag">
                              <div
                                className="new-content"
                                dangerouslySetInnerHTML={{
                                  __html: e.Title,
                                }}
                              ></div>
                            </Link>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Specifications;
