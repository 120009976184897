import { IoCloseOutline } from "@react-icons/all-files/io5/IoCloseOutline";
import React, { useState } from "react";
import "./SubscribePopup.scss";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { BlackLogo } from "../../svg";
import {
  // regex,
  caseSuccess,
  caseError,
} from "../Validations/SubscriptionForm";
import { StaticImage } from "gatsby-plugin-image";

const SubscribePopup = ({ onclick }) => {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(
    "SW8DnHlcXfL0qZ3p3A6JVFCGuZHgbQBY"
  );
  const [refreshToken, setRefreshToken] = useState(
    "6A9C0ye3apjd8TqtIdXNiKaPCqtEuLu9"
  );

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  function resLoad(setRes, setLoad) {
    setResponse(setRes);
    setIsLoading(setLoad);
  }

  const refreshTokenUrl = `https://auth.aweber.com/oauth2/token?grant_type=refresh_token&refresh_token=${refreshToken}`;

  const refreshAccessToken = async () => {
    try {
      const res = await fetch(refreshTokenUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Basic " +
            btoa(
              "FawoOSmefXDLBCV6xcxybCfKb2Fiu2wC:TXyuJq1DjoM1sMkkgpEtv84h71twxLXf"
            ),
        },
        method: "POST",
      });
      const data = await res.json();
      setAccessToken(data.access_token);

      //after refresh token adding subscriber
      // ----------------------------------------------------------------------------------------------------

      await fetch(
        "https://api.aweber.com/1.0/accounts/1958895/lists/6347465/subscribers",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
          method: "POST",
          body: JSON.stringify({
            email: email,
          }),
        }
      )
        .then((data) => {
          if (data.status == 201) {
            caseSuccess("responseMsgPopup");
            resLoad("You have successfully subscribed.", false);
            setEmail("");
          } else {
            //in case the email already used or unacceptable email
            caseError("responseMsgPopup");
            resLoad("Use Another Email.", false);
          }
        })
        .catch((err) => {
          caseError("responseMsgPopup");
          resLoad("Invalid Email.", false);
        });
      // ----------------------------------------------------------------------------------------------------
    } catch (e) {
      console.log(e);
    }
  };

  const Useremail = async () => {
    setIsLoading(true);
    if (email != "") {
      await fetch(
        "https://api.aweber.com/1.0/accounts/1958895/lists/6347465/subscribers",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          method: "POST",
          body: JSON.stringify({
            email: email,
          }),
        }
      )
        .then((data) => {
          if (data.status == 201) {
            caseSuccess("responseMsgPopup");
            resLoad("You have successfully subscribed.", false);
            setEmail("");
          } else if (data.status == 401) {
            refreshAccessToken();
          } else {
            caseError("responseMsgPopup");
            resLoad("Use Another Email.", false);
          }
        })
        .catch((err) => {
          caseError("responseMsgPopup");
          resLoad("Invalid Email.", false);
        });
    } else {
      caseError("responseMsgPopup");
      resLoad("Please enter all valid details.", false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center mt-4">
        <button type="button" className="backdrop" tabIndex={-1}></button>
        <div className="mail-main">
          <button
            type="button"
            className="close"
            onClick={() => onclick(false)}
          >
            <IoCloseOutline className="fs-4" />
          </button>
          <StaticImage
            src="../../images/Subscribe.png"
            alt="Page Not Found"
            // layout="constrained"
            objectFit="cover"
            loading="eager"
            className="subscribe-popup-img"
          />
          <div className="remi px-4 px-lg-0">
            <div className="my-3">
              <BlackLogo />
            </div>

            <h2>New reasons to get excited every week</h2>
            <p>
              Get the most important news, reviews and deals in mobile tech
              delivered straight to your inbox
            </p>
            <div className="s-input">
              <input
                value={email}
                onChange={handleChange}
                placeholder="Enter Your Email"
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  Useremail();
                }}
                className="s-subs"
              >
                SUBSCRIBE
              </button>
            </div>
            <p className="responseMsgPopup">{response}</p>
            {isLoading ? <LoadingSpinner /> : null}
            <p className="privacy">Your privacy is important to us</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscribePopup;
