import React from "react";
import Layout from "../components/Layout/layout";
import Gadgets from "../components/Gadgets/Gadgets";
// import LatestNews from "../components/LatestNews/LatestNews";
import News from "../components/News/News";
import Specifications from "../components/Specifications/Specifications";
import BannerCard from "../components/BannerCard/BannerCard";
import Reviews from "../components/Reviews/Reviews";
// import { ThemeWrapper } from "../components/DesktopTheme/DesktopTheme";
import loadable from '@loadable/component'
const LatestNews = loadable(() => import('../components/LatestNews/LatestNews'))
import Seo from "../components/Seo/seo";

// interface IndexProps {
//   counter?: (a: boolean) => void;
//   setstate?: (a: boolean) => void;
// }
// const IndexPage: React.FC<IndexProps> = () => {
const IndexPage = () => {
  return (
    <>
      <Seo
        title="Phonera Reviews - Latest Technology, Gadgets, Phones Spec"
        description="Get your insights on the Latest Technology, Gadgets, Mobile Phones with specs and reviews that guide you for the best and honest purchase."
      />
      <Layout>
        <BannerCard />
        <Reviews />
        <News />
        <Gadgets />
        <Specifications />
        <LatestNews />
      </Layout>
    </>
  );
};

export default IndexPage;